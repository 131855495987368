import { useEffect } from 'react';
const META_SELECTOR = 'meta[name="theme-color"]';
const DARK_COLOR = '#000000';
const LIGHT_COLOR = 'FFFFFF';
const getThemeColor = (isDarkMode) => (isDarkMode ? DARK_COLOR : LIGHT_COLOR);
export const useThemeColor = () => {
    useEffect(() => {
        const meta = document.querySelector(META_SELECTOR);
        if (!meta) {
            return;
        }
        if ('matchMedia' in window) {
            const query = window.matchMedia('(prefers-color-scheme: dark)');
            // Tip: 页面打开后修改颜色正常生效 但是页面刷新后变为默认值 此时赋值不生效 需要等待一段时间再赋值才能生效
            console.debug(`[Theme]: ${query.matches ? 'Dark' : 'Light'}`);
            setTimeout(() => {
                meta.setAttribute('content', getThemeColor(query.matches));
            }, 120);
            query.addEventListener('change', (e) => {
                console.debug(`[Theme]: ${e.matches ? 'Dark' : 'Light'}`);
                meta.setAttribute('content', getThemeColor(e.matches));
            });
        }
    }, []);
};
