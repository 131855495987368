import { jsx as _jsx } from "react/jsx-runtime";
import { userService } from '@ai-agent/service';
import { DefaultFallback } from '@component/fallback';
import { LazyPage } from '@component/lazy-page';
import { createBrowserRouter, redirect } from 'react-router-dom';
export const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(LazyPage, { page: "main" }),
        loader: () => {
            if (!userService.validAccessToken) {
                return redirect('/login');
            }
            return null;
        },
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/login',
        element: _jsx(LazyPage, { page: "login" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/login/internal',
        element: _jsx(LazyPage, { page: "login/internal" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/register',
        element: _jsx(LazyPage, { page: "register" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/greeting',
        loader: () => {
            if (!userService.validAccessToken) {
                return redirect('/login');
            }
            return null;
        },
        element: _jsx(LazyPage, { page: "greeting" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    {
        path: '/loader',
        element: _jsx(LazyPage, { page: "loader" }),
        errorElement: _jsx(DefaultFallback, {}),
    },
    { path: '/__test__', element: _jsx(LazyPage, { page: "test" }) },
    {
        path: '*',
        loader: async () => redirect('/'),
        errorElement: _jsx(DefaultFallback, {}),
    },
]);
