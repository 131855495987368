import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '@component/icon';
import styles from './index.module.less';
import { useEffect, useRef, useState } from 'react';
import LoadingIcon from '@asset/icon/three-dots.svg';
export const Loading = ({ children, loading = true, delay = 0, size = 'full', className = '' }) => {
    const [show, setShow] = useState(false);
    const timer = useRef();
    useEffect(() => {
        if (loading) {
            timer.current = setTimeout(() => {
                setShow(true);
            }, delay);
        }
        else {
            setShow(false);
            clearTimeout(timer.current);
        }
        return () => clearTimeout(timer.current);
    }, [delay, loading]);
    return (_jsxs("span", { className: `${styles.container} ${styles[size]} ${className}`, children: [children, loading && show && _jsx(Icon, { src: LoadingIcon })] }));
};
