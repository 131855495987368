import { jsx as _jsx } from "react/jsx-runtime";
import { toast } from '@component/toast';
import React from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error, errorInfo: null };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        toast(error.message);
    }
    render() {
        if (this.state.hasError) {
            return (_jsx("div", { children: _jsx("h1", { children: "this.state.error?.message" }) }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
