import { jsx as _jsx } from "react/jsx-runtime";
import './index.less';
import { App } from '@app';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from '@component/error-boundary';
const entrypoint = document.getElementById('entrypoint');
(() => {
    if (!entrypoint) {
        const tip = document.createElement('strong');
        tip.textContent = 'entrypoint not found';
        tip.style.color = 'red';
        document.body.appendChild(tip);
        return;
    }
    const root = createRoot(entrypoint);
    root.render(_jsx(ErrorBoundary, { children: _jsx(App, {}) }));
})();
