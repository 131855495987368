import { toast } from '@component/toast';
export const useErrorHandler = () => {
    const handlePromiseRejection = (e) => {
        if (e instanceof Error) {
            toast(e.message);
        }
    };
    const handleError = (e) => {
        if (e instanceof Error) {
            toast(e.message);
        }
    };
    window.addEventListener('unhandledrejection', handlePromiseRejection);
    window.addEventListener('error', handleError);
    return () => {
        window.removeEventListener('unhandledrejection', handlePromiseRejection);
        window.removeEventListener('error', handleError);
    };
};
