import { jsx as _jsx } from "react/jsx-runtime";
import { router } from '@router';
import { RouterProvider } from 'react-router-dom';
import { useGoogleFont } from '@hook/use-google-font';
import { useThemeColor } from '@hook/use-theme-color';
import { useRender } from '@hook/use-render';
import { useErrorHandler } from '@hook/use-error-handler';
export const App = () => {
    useRender(App.displayName);
    useGoogleFont();
    useThemeColor();
    useErrorHandler();
    return _jsx(RouterProvider, { router: router });
};
App.displayName = 'App';
