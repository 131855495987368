import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './index.module.less';
export const DefaultFallback = () => {
    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     location.assign('/');
    //   }, 3000);
    //   return () => clearTimeout(timer);
    // }, []);
    return (_jsxs("div", { className: styles.container, children: [_jsx("span", { children: "Something error" }), _jsx("a", { href: "/", children: "Return to the homepage" })] }));
};
