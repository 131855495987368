import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './index.module.less';
import { createRoot } from 'react-dom/client';
export const Toast = ({ content, action, onClose }) => (_jsx("div", { className: styles['toast-container'], children: _jsxs("div", { className: styles['toast-content'], children: [_jsx("span", { children: content }), action && (_jsx("button", { onClick: () => {
                    action?.onClick?.();
                    onClose?.();
                }, className: styles['toast-action'], children: action.text }))] }) }));
export function toast(content, action, delay = 3000) {
    const div = document.createElement('div');
    div.classList.add(styles.show, styles.toast);
    document.body.appendChild(div);
    const root = createRoot(div);
    const close = () => {
        div.classList.add(styles.hide);
        setTimeout(() => {
            root.unmount();
            div.remove();
        }, 300);
    };
    setTimeout(() => {
        close();
    }, delay);
    root.render(_jsx(Toast, { content: content, action: action, onClose: close }));
}
